import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config"; 

function Projects() {
    const { t } = useTranslation();
    const [projects, setProjects] = useState([]);
    const [filter, setFilter] = useState('*');

    useEffect(() => {
        const fetchProjects = async () => {
            const projectsCollection = collection(db, "projects");
            const projectSnapshot = await getDocs(projectsCollection);
            const projectList = projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProjects(projectList);
        };

        fetchProjects();
    }, []);

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const filteredProjects = filter === '*'
        ? projects
        : projects.filter(project => project.category === filter);

    return (
        <div id="project_div" className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                    <p className="section-title text-secondary justify-content-center">
                        <span></span>{t('projects.title')}<span></span>
                    </p>
                    <h1 className="text-center mb-5">{t('projects.header')}</h1>
                </div>
                <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="col-12 text-center">
                        <ul className="list-inline mb-5" id="portfolio-flters">
                            <li 
                                className={`mx-2 ${filter === '*' ? 'active' : ''}`} 
                                onClick={() => handleFilterChange('*')}
                            >
                                {t('projects.filters.all')}
                            </li>
                            <li 
                                className={`mx-2 ${filter === 'mobile_apps' ? 'active' : ''}`} 
                                onClick={() => handleFilterChange('mobile_apps')}
                            >
                                {t('projects.filters.mobile_apps')}
                            </li>
                            <li 
                                className={`mx-2 ${filter === 'websites' ? 'active' : ''}`} 
                                onClick={() => handleFilterChange('websites')}
                            >
                                {t('projects.filters.websites')}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row g-4 portfolio-container">
                    {filteredProjects.map((project, index) => (
                        <div key={project.id} className={`col-lg-4 col-md-6 portfolio-item ${project.category} wow fadeInUp`} data-wow-delay={`${0.1 * index}s`}>
                            <div className="rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src={project.image_url} alt={project.title} />
                                    <div className="portfolio-overlay">
                                        <a className="btn btn-square btn-outline-light mx-1" href={project.lightbox_url} data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                        <a className="btn btn-square btn-outline-light mx-1" href={project.project_link}><i className="fa fa-link"></i></a>
                                    </div>
                                </div>
                                <div className="bg-light p-4">
                                    <p className="text-primary fw-medium mb-2">{project.category === "mobile_apps" ? "Mobile Application" : "Website"}</p>
                                    <h5 className="lh-base mb-0">{project.title}</h5>
                                    <div className="tags mt-3">
                                        {project.tags.map((tag, tagIndex) => (
                                            <span key={tagIndex} className="badge badge-purple mr-2 mb-2">{tag}</span> 
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Projects;