import React from 'react';
import { useTranslation } from 'react-i18next';

function AboutUs() {
    const { t } = useTranslation();
  return (
    <div id="about_div" className="container-xxl py-5">
      <div className="container py-5 px-lg-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <p className="section-title text-secondary">{t('about.title')}<span></span></p>
            <h1 className="mb-5">{t('about.header')}</h1>
            <p className="mb-4">{t('about.desc')}</p>
          </div>
          <div className="col-lg-6">
         
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
