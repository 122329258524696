import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../utility/LanguageSwitcher.js';

function Navbar() {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className='container-xxl position-relative p-0'>
      <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <div href="#" className="navbar-brand p-0">
          <a href='/'><h1 className="m-0">Cloud Software</h1></a>
        </div>
        <button
          className={`navbar-toggler ${isNavOpen ? 'collapsed' : ''}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          onClick={toggleNav}
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarCollapse">
          <div className="navbar-nav mx-auto py-0">
            <LanguageSwitcher />
            <a href="/" className="nav-item nav-link active">
              {t('navbar.home')}
            </a>
            <a href='./about' className="nav-item nav-link">{t('navbar.about')}</a>
            <a href='./service' className="nav-item nav-link">{t('navbar.service')}</a>
            <a href='/project' className="nav-item nav-link">{t('navbar.project')}</a>
            <a href="/blog" className="nav-item nav-link">
              {t('navbar.blog')}
            </a>
          </div>
          <a href="https://www.facebook.com/cloudsoftwaretn1"><p className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">
            {t('navbar.hireUs')}
          </p></a>
        </div>
      </nav>
     
      </div>

  
 
   
  );
}

export default Navbar;
