import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../../firebase/config"; 
import AddTestimonial from './AddTestimonial'; // Import the form component
import 'owl.carousel/dist/owl.carousel.min.js';

function Testimonial() {
  const { t } = useTranslation();
  const [testimonialData, setTestimonialData] = useState([]);

  const fetchTestimonials = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'testimonials'));
      const testimonials = querySnapshot.docs.map(doc => doc.data());
      setTestimonialData(testimonials);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  return (
    <div id="testi_div" className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5 px-lg-5">
        <p className="section-title text-secondary justify-content-center"><span></span>{t('TESTIMONIAL.title')}<span></span></p>
        <h1 className="text-center mb-5">{t('TESTIMONIAL.header')}</h1>
        <div>
          <div className="col-12 mb-5">
            <AddTestimonial fetchTestimonials={fetchTestimonials} /> {/* Include the form */}
          </div>
          {testimonialData.map((item, index) => (
            <div key={index} className="testimonial-item bg-light rounded my-4">
              <p className="fs-5"><i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>{item.quote}</p>
              <div className="d-flex align-items-center">
                <img className="img-fluid flex-shrink-0 rounded-circle" src={item.imageSrc} style={{ width: '65px', height: '65px' }} alt={`client-${index}`} />
                <div className="ps-4">
                  <h5 className="mb-1">{item.name}</h5>
                  <span>{item.role}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
