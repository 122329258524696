import BlogItem from "./BlogItem";

const BlogList = () => {
    return (  

        <div>
             <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
<h1>Still under development ;(</h1>
            {/* <BlogItem/> */}
        </div>
    );
}
 
export default BlogList;