import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './component/utility/i18n';
import Homepage from './component/UI_build/Homepage';
import Team from './component/UI_build/Team';
import Aboutus from './component/UI_build/Aboutus';
import Service from './component/UI_build/Service';
import Projects from './component/UI_build/Projects';
import Navbar from './component/UI_build/Navbar';
import Footer from './component/UI_build/Footer';
import PageNotFound from './res/404';
import Loading from './Loading';
import BlogList from './component/UI_build/blog/BlogList';


import Iframe from './component/UI_build/Iframe';
import AddProject from './admin/AddProjects';
import AddTestimonial from './admin/AddTestimonial';
import AddBlogForm from './admin/AddBlogForm';
import TwentyFourYears from './special/24y';
function App() {
  const [loaded, SetLoad] = useState(false);

  useEffect(() => {
    // Simulate loading delay (remove this in production)
    setTimeout(() => {
      SetLoad(true);
    }, 300); // Adjust the delay as needed
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="container-xxl bg-white p-0">
        <Navbar/>
        <BrowserRouter>
          <Routes>
            {!loaded && <Route path="/" element={<Loading />} />}
        
            <Route path="/" element={<Homepage />} />
            <Route path="/team" element={<Team />} />
            <Route path="/about" element={<Aboutus />} />
            <Route path="/service" element={<Service />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/24" element={<TwentyFourYears />} />
            {/* <Route path="/admin" element={<AddBlogForm  />} /> */}
            
        
            <Route
              path="/store"
              element={
                <Iframe
                  src="https://jemalistore.com/" // Replace with your desired URL
                  title="External Website"
                  width="100%" // Adjust the width as needed
                  height="500" // Adjust the height as needed
                />
              }
            />
          
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/*" element={<Navigate to="/404" />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </I18nextProvider>
  );
}

export default App;
