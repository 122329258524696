import Aboutus from "./Aboutus";
import Feature from "./Feature";
import Facts from "./Facts";
import Service from "./Service";
import Projects from "./Projects";
import Testimonial from "./Testimonial";
import Team from "./Team";
import React from "react";
import Splash from "./Splash";

function Homepage(){
    return(
<div>
 <div  className="container-xxl bg-primary hero-header">
      <Splash/>
      </div>
      <Feature/>
      <Aboutus/>


      <Facts/>
      <Service/>
      <Projects/>
      <Testimonial/>
      <Team/>
</div>
      
    );
}


export default Homepage;