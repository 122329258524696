import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import the language detector

import enTranslation from '../../locales/en.json'; 
import frTranslation from '../../locales/fr.json';
import arTranslation from '../../locales/ar.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation }, 
    fr: { translation: frTranslation },
    ar: { translation: arTranslation }, 
    // Add other languages here
 
 
  },
  lng: "en", // Default language
  fallbackLng: 'ar', // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
