import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase/config"; 
import './badge.css';
function AddProject() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [lightboxUrl, setLightboxUrl] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "projects"), {
        title,
        description,
        category,
        image_url: imageUrl,
        lightbox_url: lightboxUrl,
        project_link: projectLink,
        tags,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp()
      });
      setTitle("");
      setDescription("");
      setCategory("");
      setImageUrl("");
      setLightboxUrl("");
      setProjectLink("");
      setTags([]);
      alert("Project added successfully!");
    } catch (e) {
      console.error("Error adding project: ", e);
    }
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
      e.preventDefault();
    }
  };

  const handleTagDelete = (tag) => {
    setTags(tags.filter(t => t !== tag));
  };

  return (
    <div className="container mt-5">
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <h2>Add Project</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Project Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            className="form-control"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Select Category</option>
            <option value="mobile_apps">Mobile Applications</option>
            <option value="websites">Websites</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="imageUrl">Image URL</label>
          <input
            type="url"
            className="form-control"
            id="imageUrl"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lightboxUrl">Lightbox URL</label>
          <input
            type="url"
            className="form-control"
            id="lightboxUrl"
            value={lightboxUrl}
            onChange={(e) => setLightboxUrl(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="projectLink">Project Link</label>
          <input
            type="url"
            className="form-control"
            id="projectLink"
            value={projectLink}
            onChange={(e) => setProjectLink(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">Technologies Used (Tags)</label>
          <input
            type="text"
            className="form-control"
            id="tags"
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
            placeholder="Press Enter to add a tag"
          />
          <div className="mt-2">
            {tags.map((tag, index) => (
              <span key={index} className="badge badge-primary mr-2">
                {tag} 
                <button type="button" className="btn btn-sm btn-light ml-1" onClick={() => handleTagDelete(tag)}>&times;</button>
              </span>
            ))}
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Add Project
        </button>
      </form>
    </div>
  );
}

export default AddProject;
