

function ArrowUp(){

    return (

        <a href="#" class="btn btn-lg btn-secondary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
 
    );
}

export default ArrowUp;