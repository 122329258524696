import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from "../../firebase/config";

const AddTestimonial = ({ fetchTestimonials }) => {
  const [quote, setQuote] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [imageSrc, setImageSrc] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'testimonials'), {
        quote,
        name,
        role,
        imageSrc:"https://i.pinimg.com/564x/7c/ee/6f/7cee6fa507169843e3430a90dd5377d4.jpg",
      });
      setQuote('');
      setName('');
      setRole('');
      setImageSrc('');
      fetchTestimonials(); 
    } catch (error) {
      console.error('Error adding testimonial:', error);
    }
  };

  return (
    <div className="container-xxl py-5">
      <div className="container py-5 px-lg-5">
        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-12">
              <div className="form-floating">
                <textarea
                  className="form-control"
                  id="quote"
                  value={quote}
                  onChange={(e) => setQuote(e.target.value)}
                  placeholder="Your Quote"
                  style={{ height: '150px' }}
                  required
                ></textarea>
                <label htmlFor="quote">Your Quote</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your Name"
                  required
                />
                <label htmlFor="name">Your Name</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Your Role"
                  required
                />
                <label htmlFor="role">Your Role</label>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="imageSrc"
                  value={imageSrc}
                  onChange={(e) => setImageSrc(e.target.value)}
                  placeholder="Image URL"
                  required
                />
                <label htmlFor="imageSrc">Image URL</label>
              </div>
            </div> */}
            <div className="col-12">
              <button className="btn btn-primary w-100 py-3" type="submit">
                Add Testimonial
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTestimonial;
