import ArrowUp from "../utility/Arrow_up";
import { useTranslation } from "react-i18next";

function Footer(){
 const { t } = useTranslation();
    return (
        <div className="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5 px-lg-5">
            <div className="row g-5">
                <div className="col-md-6 col-lg-3">
                    <p className="section-title text-white h5 mb-4">{t('footer.address')}<span></span></p>
                    <p><i className="fa fa-map-marker-alt me-3"></i>9180 Sidi Bouzid Tunisia</p>
                    <p><i className="fa fa-phone-alt me-3"></i>Not set</p>
                    <p><i className="fa fa-envelope me-3"></i>contact@cloudsoftware.tn</p>
                    <div className="d-flex pt-2">
                        
                        <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/cloudsoftwaretn1"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/cloudsoftware.tn"><i className="fab fa-instagram"></i></a>
                        
                    </div>
                </div>
           
               
                <div className="col-md-6 col-lg-3">
                    <p className="section-title text-white h5 mb-4">{t('footer.newsletter')}<span></span></p>
                    <div className="position-relative w-100 mt-3">
                        <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style={{height: '48px'}}/>
                        <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary fs-4"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="container px-lg-5">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <b className="border-bottom">Cloud Software Inc</b>, All Right Reserved. 
                        
                    </div>
               
                </div>
            </div>
        </div>
        <ArrowUp/>
    </div>

    );
}

export default Footer;