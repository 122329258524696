// src/TwentyFourYears.js
import React, { useState, useCallback, useRef, useEffect } from 'react';
import './TwentyFourYears.css';

const numRows = 30;
const numCols = 50;

// Initialize the grid with the Gosper Glider Gun pattern
const generateGosperGliderGun = () => {
  const grid = Array.from({ length: numRows }, () =>
    Array.from({ length: numCols }, () => 0)
  );

  const patterns = {
    gliderGun: [
      [1, 25], [2, 23], [2, 25], [3, 13], [3, 14], [3, 21], [3, 22], [3, 35], [3, 36],
      [4, 12], [4, 16], [4, 21], [4, 22], [4, 35], [4, 36],
      [5, 1], [5, 2], [5, 11], [5, 17], [5, 21], [5, 22],
      [6, 1], [6, 2], [6, 11], [6, 15], [6, 17], [6, 18], [6, 23], [6, 25],
      [7, 11], [7, 17], [7, 25],
      [8, 12], [8, 16],
      [9, 13], [9, 14]
    ],
    pulsar: [
      [10, 12], [10, 13], [10, 14], [10, 18], [10, 19], [10, 20],
      [12, 10], [12, 15], [12, 17], [12, 22],
      [13, 10], [13, 15], [13, 17], [13, 22],
      [14, 10], [14, 15], [14, 17], [14, 22],
      [15, 12], [15, 13], [15, 14], [15, 18], [15, 19], [15, 20],
      [17, 12], [17, 13], [17, 14], [17, 18], [17, 19], [17, 20],
      [18, 10], [18, 15], [18, 17], [18, 22],
      [19, 10], [19, 15], [19, 17], [19, 22],
      [20, 10], [20, 15], [20, 17], [20, 22],
      [22, 12], [22, 13], [22, 14], [22, 18], [22, 19], [22, 20]
    ],
    glider: [
      [1, 1], [2, 2], [3, 0], [3, 1], [3, 2]
    ]
    // Add more patterns as needed
  };

  const selectedPattern = patterns.gliderGun; // Change this to switch between patterns

  selectedPattern.forEach(([x, y]) => {
    grid[x][y] = 1;
  });

  return grid;
};

// Directions to check the eight neighbors of a cell
const operations = [
  [0, 1],
  [0, -1],
  [1, 0],
  [-1, 0],
  [1, 1],
  [1, -1],
  [-1, 1],
  [-1, -1],
];

const TwentyFourYears = () => {
  // State variables to manage the grid, running status, generation count, and live/dead cell counts
  const [grid, setGrid] = useState(() => generateGosperGliderGun());
  const [running, setRunning] = useState(false);
  const [generation, setGeneration] = useState(0);
  const [liveCells, setLiveCells] = useState(0);
  const [deadCells, setDeadCells] = useState(0);

  const runningRef = useRef(running);
  runningRef.current = running;

  // Simulation loop that updates the grid according to the rules
  const runSimulation = useCallback(() => {
    if (!runningRef.current) {
      return;
    }

    setGrid((g) => {
      const newGrid = g.map((row, i) =>
        row.map((cell, j) => {
          let neighbors = 0;
          operations.forEach(([x, y]) => {
            const newI = i + x;
            const newJ = j + y;
            if (newI >= 0 && newI < numRows && newJ >= 0 && newJ < numCols) {
              neighbors += g[newI][newJ];
            }
          });
          if (cell === 1 && (neighbors < 2 || neighbors > 3)) {
            return 0;
          } else if (cell === 0 && neighbors === 3) {
            return 1;
          } else {
            return cell;
          }
        })
      );

      const live = newGrid.flat().filter((cell) => cell === 1).length;
      const dead = newGrid.flat().filter((cell) => cell === 0).length;

      setLiveCells(live);
      setDeadCells(dead);

      return newGrid;
    });

    setGeneration((gen) => gen + 1);
    setTimeout(runSimulation, 100);
  }, []);

  // Initial calculation of live and dead cells when the component mounts
  useEffect(() => {
    const live = grid.flat().filter((cell) => cell === 1).length;
    const dead = grid.flat().filter((cell) => cell === 0).length;

    setLiveCells(live);
    setDeadCells(dead);
  }, [grid]);

  return (
    <div className="game-container">
      {document.title = `Game of Life - 24th Birthday Special` }
      <br/>
      <br/>
      <br/>
      <br/>
      <h1>Game of Life - 24th Birthday Special</h1>
      <button
        onClick={() => {
          setRunning(!running);
          if (!running) {
            runningRef.current = true;
            runSimulation();
          }
        }}
      >
        {running ? 'Stop' : 'Start'}
      </button>
      <button
        onClick={() => {
          setGrid(generateGosperGliderGun());
          setGeneration(0);
        }}
      >
        Reset
      </button>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${numCols}, 2vw)`, // Adjusted for mobile responsiveness
          gap: '1px',
        }}
      >
        {grid.map((rows, i) =>
          rows.map((col, j) => (
            <div
              key={`${i}-${j}`}
              onClick={() => {
                const newGrid = grid.slice();
                newGrid[i][j] = grid[i][j] ? 0 : 1;
                setGrid(newGrid);
              }}
              style={{
                width: '2vw', // Adjusted for mobile responsiveness
                height: '2vw', // Adjusted for mobile responsiveness
                backgroundColor: grid[i][j] ? 'black' : undefined,
                border: 'solid 1px gray',
              }}
            />
          ))
        )}
      </div>
      <h2>Generation: {generation}</h2>
      <h2>Live Cells: {liveCells}</h2>
      <h2>Dead Cells: {deadCells}</h2>
    </div>
  );
};

export default TwentyFourYears;
