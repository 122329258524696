import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from "../../firebase/config"; 
import { doc, getDoc } from 'firebase/firestore';

function Facts() {
  const { t } = useTranslation();
  const [facts, setFacts] = useState({ xp: 0, team: 0, happy: 0, project: 0 });

  useEffect(() => {
    const fetchFacts = async () => {
      const factsDocRef = doc(db, 'facts', 'statistics');
      const factsDoc = await getDoc(factsDocRef);
      if (factsDoc.exists()) {
        setFacts(factsDoc.data());
      }
    };

    fetchFacts();
  }, []);

  return (
    <div className="container-xxl bg-primary fact py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5 px-lg-5">
        <div className="row g-4">
          <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
            <i className="fa fa-certificate fa-3x text-secondary mb-3"></i>
            <h1 className="text-white mb-2" data-toggle="counter-up">{facts.xp}</h1>
            <p className="text-white mb-0">{t('facts.xp')}</p>
          </div>
          <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
            <i className="fa fa-users-cog fa-3x text-secondary mb-3"></i>
            <h1 className="text-white mb-2" data-toggle="counter-up">{facts.team}</h1>
            <p className="text-white mb-0">{t('facts.team')}</p>
          </div>
          <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
            <i className="fa fa-users fa-3x text-secondary mb-3"></i>
            <h1 className="text-white mb-2" data-toggle="counter-up">{facts.happy}</h1>
            <p className="text-white mb-0">{t('facts.happy')}</p>
          </div>
          <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
            <i className="fa fa-check fa-3x text-secondary mb-3"></i>
            <h1 className="text-white mb-2" data-toggle="counter-up">{facts.project}</h1>
            <p className="text-white mb-0">{t('facts.project')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Facts;
