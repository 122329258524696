import React from "react";
import { useTranslation } from 'react-i18next';
function Feature(){
    const { t } = useTranslation();
    return (
        <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="row g-4">
                    <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded text-center p-4">
                            <i className="fa fa-3x fa-mobile-alt text-primary mb-4"></i>
                            <h5 className="mb-3">{t('feature.mobile')}</h5>
                            <p className="m-0">{t('feature.mobile_desc')}
                            </p>
                        </div>
                        
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item bg-light rounded text-center p-4">
                            <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                            <h5 className="mb-3">{t('feature.website')} </h5>
                            <p className="m-0"> {t('feature.website_desc')} </p>
                        </div>
                        
                        
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                        
                    <div className="feature-item bg-light rounded text-center p-4">
             <i className="fa fa-3x fa-desktop text-primary mb-4"></i>
                 <h5 className="mb-3">{t('feature.pc')} </h5>
                 <p className="m-0">{t('feature.pc_desc')} </p>
                </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Feature;