import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // Initialize the language from local storage or use the default language
  const initialLanguage = localStorage.getItem('selectedLanguage') || 'en';
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    setIsOpen(false);

    // Save the selected language in local storage
    localStorage.setItem('selectedLanguage', lng);
  };

  // Listen for changes in the selectedLanguage state
  useEffect(() => {
    // Update the i18n language when the selectedLanguage changes
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div className={`nav-item dropdown ${isOpen ? 'show' : ''}`}>
      <p
        className="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedLanguage ? (
          <>
            <span role="img" aria-label="globe">🌐</span> {selectedLanguage.toUpperCase()}
          </>
        ) : null}
      </p>
      <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
        <p className="dropdown-item" onClick={() => changeLanguage('en')}>En</p>
        <p className="dropdown-item" onClick={() => changeLanguage('fr')}>Fr</p>
        <p className="dropdown-item" onClick={() => changeLanguage('ar')}>عربي</p>
        {/* Add more language options */}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
