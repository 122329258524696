import React  from "react";
import { useTranslation } from "react-i18next";


function Service(){
    const { t } = useTranslation(); // Initialize the useTranslation hook

    return (
      <div id="service_div">
        <div className="container-xxl py-5">
          <div className="container py-5 px-lg-5">
            <div className="wow fadeInUp" data-wow-delay="0.1s">
              <p className="section-title text-secondary justify-content-center"><span></span>{t("service.title")}<span></span></p>
              <h1 className="text-center mb-5">{t("service.header")}</h1>
            </div>
            <div className="row g-4">
              {t("service.items", { returnObjects: true }).map((item, index) => (
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={`0.${index + 1}s`} key={index}>
                  <div className="service-item d-flex flex-column text-center rounded">
                    <div className="service-icon flex-shrink-0">
                      <i className={item.icon}></i>
                    </div>
                    <h5 className="mb-3">{item.title}</h5>
                    <p className="m-0">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Service;