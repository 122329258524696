import React from "react";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();

  const teamMembers = [
    {
      name: "Chahid Ahmadi",
      role: t("team.founder"),
      imageSrc: "img/team/chahid.gif",
      socialLinks: {
        facebook: "https://www.facebook.com/cloudsoftwaretn1",
        github: "https://github.com/cloudsoftwareoff",
        linkedin: "https://www.linkedin.com/in/chahid-ahmadi-278982180/",
      },
    },
    {
      name: "Amine Jemali",
      role: t("team.BDM"),
      imageSrc: "img/team/amino.gif",
      socialLinks: {
        facebook: "https://www.facebook.com/jemmali.amine",
        github: "https://github.com/Aminejemmali",
        linkedin: "https://www.linkedin.com/in/amine-jemmali-9626781a2",
      },
    },
    {
      name: "T0xica",
      role: t("team.web"),
      imageSrc: "https://i.pinimg.com/564x/d7/ef/ff/d7efff92d430962cba65557e6a2bee07.jpg",
      socialLinks: {
      //   facebook: "/",
      //   twitter: "/",
      //   instagram: "/",
      //   linkedin: "/",
      },
    },
    // Add more team members here...
  ];

  return (
    <div id="team_div" className="container-xxl py-5">
      <div className="container py-5 px-lg-5">
        <div className="wow fadeInUp" data-wow-delay="0.1s">
          <p className="section-title text-secondary justify-content-center">
            <span></span>
            {t("team.title")}
            <span></span>
          </p>
          <h1 className="text-center mb-5">{t("team.header")}</h1>
        </div>
        <div className="row g-4">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className={`col-lg-4 col-md-6 wow fadeInUp`}
              data-wow-delay={`0.${index + 1}s`}
            >
              <div className="team-item bg-light rounded">
                <div className="text-center border-bottom p-4">
                  <img
                    className="img-fluid rounded-circle mb-4"
                    src={member.imageSrc}
                    alt=""
                  />
                  <h5>{member.name}</h5>
                  <span>{member.role}</span>
                </div>
                <div className="d-flex justify-content-center p-4">
                  <a
                    className="btn btn-square mx-1"
                    href={member.socialLinks.facebook}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="btn btn-square mx-1"
                    href={member.socialLinks.github}
                  >
                    <i className="fab fa-github"></i>
                  </a>
                  <a
                    className="btn btn-square mx-1"
                    href={member.socialLinks.linkedin}
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  {member.socialLinks.twitter && (
                    <a
                      className="btn btn-square mx-1"
                      href={member.socialLinks.twitter}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  )}
                  {member.socialLinks.instagram && (
                    <a
                      className="btn btn-square mx-1"
                      href={member.socialLinks.instagram}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
