import { useTranslation } from 'react-i18next';

function Splash() {

    const { t } = useTranslation(); 
    return (
      <div className="container px-lg-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 text-center text-lg-start">
            <h1 className="text-white mb-4 animated slideInDown">
            {t('splash.slogan')}</h1>
            <p className="text-white pb-3 animated slideInDown">
            {t('splash.introduction')}    </p>
            <a href="https://www.facebook.com/cloudsoftwaretn1" className="btn btn-secondary py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft">{t('splash.contact')}</a>
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <img className="img-fluid animated zoomIn" src={process.env.PUBLIC_URL + "/img/dev.png"} alt="Digital Innovation" />
          </div>
        </div>
      </div>
    );
  }
  
  export default Splash;
  