import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
  apiKey: "AIzaSyAQg6hsvAazcnuZITBfffYZgSOCZMBrqhI",
  authDomain: "cloudsoftwaretn.firebaseapp.com",
  projectId: "cloudsoftwaretn",
  storageBucket: "cloudsoftwaretn.appspot.com",
  messagingSenderId: "253285076453",
  appId: "1:253285076453:web:c51ba0e0f0cc6d041bb30c",
  measurementId: "G-99NJ71B10V"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Firebase services
export const auth = getAuth(app);
export const storage =getStorage(app);
export const db = getFirestore(app);