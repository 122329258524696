import {useEffect,React} from "react";

const Iframe = ({ src, title, width, height }) => {
  useEffect(() => {
    // Set the new title when the component mounts
    document.title = 'Jemali Store';
   } );
  return (
    <div>
      <br></br>
    <iframe
      src={src}
      title={title}
      width={width}
      height={height}
      frameBorder="0"
      allowFullScreen
    ></iframe>
    </div>
  );
};

export default Iframe;
